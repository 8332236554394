import {
  FunctionComponent,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { Success } from "./Success";
import "./App.css";

interface FormProps {
  validate: (value: string) => void;
  showError: boolean;
}
const Form: FunctionComponent<FormProps> = ({ validate, showError }) => {
  const [password, setPassword] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);
  const onClick = useCallback(() => {
    validate(password);
    inputRef.current?.focus();
  }, [inputRef, password, validate]);
  return (
    <div className="Form">
      <h1>Entrez le code</h1>
      <input
        ref={inputRef}
        id="password"
        type="text"
        autoComplete="off"
        value={password}
        onChange={({ target: { value } }) => setPassword(value)}
      />
      <button aria-label="Valider" onClick={onClick}>
        Déverrouiller
      </button>
      <div className="message-container">
        {showError ? <p>Ce n'est pas le bon code</p> : null}
      </div>
    </div>
  );
};

const appDefaultStyle: React.CSSProperties = {
  background: `no-repeat center center border-box url(${process.env.PUBLIC_URL}/coffre_fort.jpeg)`,
  backgroundSize: "cover",
};

function App() {
  const [status, setStatus] = useState<null | "error" | "success">(null);
  const [style, setStyle] = useState<React.CSSProperties>(appDefaultStyle);

  const validateForm = useCallback(
    (value: string) => {
      if (value.toUpperCase() === "NATUREL") {
        setStatus("success");
      } else {
        setStatus("error");
        setStyle(appDefaultStyle);
      }
    },
    [setStatus, setStyle]
  );
  return (
    <div className="App" style={style}>
      {status === "success" ? (
        <Success setStyle={setStyle} />
      ) : (
        <Form validate={validateForm} showError={status === "error"} />
      )}
    </div>
  );
}

export default App;
